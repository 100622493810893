import {Pages}                   from "./Pages";
import React                     from 'react';
import ReactDOM                 from "react-dom";
import {EntitiesSearchDropList} from "../components/EntitiesSearchDropList";
import {Entities}               from "../enums/Entities";

export class Programs extends Pages {

    constructor(params) {
        super(params);
    }

    initPageHandlers(params) {
        super.initPageHandlers(params);
        this.data = window.diaryJsData;

        ReactDOM.render(
            <EntitiesSearchDropList
                placeholder={"быстрый поиск по названию программы"}
                entity={Entities.PROGRAMS}/>,
            document.getElementById('programs-list-search-form-component'),
        );

    }
}
